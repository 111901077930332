<template>
    <v-row class="pt-2">
        <v-col cols="12">
            <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 6 : 2" :disabled="loading">
                    <v-overlay :absolute="true" :opacity="0.4" :value="asset.maintenance">
                        <div class="text-center">
                            <v-icon x-large>mdi-wrench</v-icon>
                            <div class="text-h4">อยู่ระหว่างการซ่อมบำรุง</div>
                        </div>
                    </v-overlay>
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12" md="1">
                                <!-- <v-avatar class="mr-2" size="100" tile> -->
                                <v-img height="100px"
                                    :src="asset.MapImage || require('@/assets/undraw_photos_re_pvh3.svg')" />
                                <!-- </v-avatar> -->
                            </v-col>
                            <v-col cols="12" md="auto">
                                <div
                                    :class="(asset.maintenance) ? 'text-left text-h5 font-weight-bold grey--text' : 'text-left text-h5 font-weight-bold black--text'">
                                    <v-icon>mdi-waves-arrow-up</v-icon>{{ asset.assetName }} <v-icon
                                        v-if="asset.maintenance" small>mdi-wrench</v-icon>
                                    <ASSET_ALERT_ICON :asset="asset" />
                                </div>
                                <div class="text-left text-subtitle-1">{{ asset.location }} </div>
                                <div class="text-left text-subtitle-1">ข้อมูลเมื่อ {{
                                    $dayjs(asset.heartbeat).format('HH:mm DD MMM YYYY') }}</div>
                                <ASSET_MENU :asset="asset" />
                            </v-col>
                            <v-spacer />
                            <v-col cols="12" md="auto">
                                เลือกช่วงเวลา
                                <DateRangePicker class="" v-model="range" />
                            </v-col>
                            <v-col cols="12" md="auto"> <v-btn color="primary" :disabled="loading" @click="refresh"
                                    :block="$vuetify.breakpoint.mobile">VIEW</v-btn></v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="2">
                                <div ref="levelGuage" style="width: 100%;height:250px;"></div>
                                <div class="text-center text-h6 font-weight-bold black--text">
                                    <v-icon>mdi-pressure</v-icon>
                                    Water Level
                                </div>
                            </v-col>

                            <v-col cols="12" md="10">
                                <!--   <Line_Chart :element="'WaterLevel'" :data="level" :min="0" :max="'auto'" :unit="'cm'" /> -->
                                <div ref="levelChart" style="width: 100%;height:250px;"></div>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="asset.pressureId">
                            <v-col cols="12" md="2">
                                <div ref="pressureGuage" style="width: 100%;height:250px;"></div>
                                <!-- <div class="text-center text-h5 font-weight-bold black--text">  {{ Number(JSON.parse(pressureAsset.data).WaterPressure).toFixed(0) }} PSI. </div> -->
                                <div class="text-center text-h6 font-weight-bold black--text"> {{
                                    pressureAsset.assetName }} </div>
                                <div class="text-center black--text"> {{ pressureAsset.location }} </div>
                                <div class="text-center black--text"> ข้อมูลเมื่อ {{
                                    $dayjs(pressureAsset.heartbeat).format('HH:mm DD MMM YYYY')
                                    }} </div>
                                <div class="text-center black--text"> <v-icon>mdi-gauge</v-icon>
                                    Water Pressure
                                </div>
                                <ASSET_MENU :asset="pressureAsset" :align="'center'" />
                            </v-col>
                            <v-col cols="12" md="10">
                                <div ref="pressureChart" style="width: 100%;height:250px;"></div>
                            </v-col>
                        </v-row>
                        <v-row dense v-if="asset.pumpId">
                            <v-col cols="12" md="2">
                                <div ref="energyCompare" style="width: 100%;height:250px;"></div>
                                <div class="text-center text-h5 font-weight-bold black--text"> {{
                                    Number(JSON.parse(pumpAsset.data).EnergyConsumption).toFixed(0) }} kW </div>
                                <div class="text-center text-h6 font-weight-bold black--text"> {{ pumpAsset.assetName }}
                                </div>
                                <div class="text-center black--text"> {{ pumpAsset.location }} </div>
                                <div class="text-center black--text"> ข้อมูลเมื่อ {{
                                    $dayjs(pumpAsset.heartbeat).format('HH:mm DD MMM YYYY') }}
                                </div>
                                <div class="text-center black--text"> <v-icon>mdi-gauge</v-icon>
                                    Booster Pump Meter
                                </div>
                                <ASSET_MENU :asset="pumpAsset" :align="'center'" />
                            </v-col>
                            <v-col cols="12" md="10">
                                <div ref="pumpChart" style="width: 100%;height:250px;"></div>
                            </v-col>

                        </v-row>
                        <v-row dense v-if="asset.meterId">
                            <v-col cols="12" md="2">
                                <div ref="meterCompare" style="width: 100%;height:250px;"></div>
                                <div class="text-center text-h5 font-weight-bold black--text"> {{
                                    Number(JSON.parse(meterAsset.data).Unit).toFixed(0) }} Unit. </div>
                                <div class="text-center text-h6 font-weight-bold black--text"> {{ meterAsset.assetName
                                    }} </div>
                                <div class="text-center black--text"> {{ meterAsset.location }} </div>
                                <div class="text-center black--text"> ข้อมูลเมื่อ {{
                                    $dayjs(meterAsset.heartbeat).format('HH:mm DD MMM YYYY') }}
                                </div>
                                <div class="text-center black--text"> <v-icon>mdi-gauge</v-icon>
                                    Water Meter
                                </div>
                                <ASSET_MENU :asset="pumpAsset" :align="'center'" />
                            </v-col>
                            <v-col cols="12" md="10">
                                <div ref="meterChart" style="width: 100%;height:250px;"></div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-hover>
        </v-col>
    </v-row>
</template>

<script>
// import { getTelemetry } from '@/api/'
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    DatasetComponent,
    VisualMapComponent,
    MarkAreaComponent
} from 'echarts/components';

import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
//import SevenSegmentDisplay from 'vue-seven-segment-display';
import DateRangePicker from '@/components/common/DateRangePicker.vue'
import ASSET_MENU from '@/components/asset/asset_menu.vue'
import ASSET_ALERT_ICON from '@/components/asset/asset_alert_icon.vue'

echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
    DatasetComponent,
    VisualMapComponent,
    MarkAreaComponent
]);

export default {
    props: {
        asset: {
            type: Object
        },
    },
    components: {
        DateRangePicker,
        ASSET_MENU,
        ASSET_ALERT_ICON
    },
    destroyed() {
        this.interval = null
    },
    watch: {
        meter(val) {
            if (val) {
                this.setData()
            }
        },
        levelData() {
            this.setData()
        },
        asset() {
            this.refresh()
        }
    },
    data() {
        return {
            levelChart: null,
            levelGuage: null,
            pressureGuage: null,
            pressureChart: null,
            pumpChart: null,
            energyCompare: null,
            meterCompare: null,
            meterChart: null,
            interval: null,
            loading: false,
            range: {
                startDate: this.$dayjs().local().startOf('day').toDate(),
                endDate: this.$dayjs().local().endOf('day').toDate(),
            },
            barOption: {
                title: {
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: '{c} cm.'
                },
                toolbox: {
                    show: false,
                    feature: {
                        saveAsImage: {}
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },

                xAxis: {
                    type: 'category',
                    data: ['Level']
                },
                yAxis: {
                    type: 'value',
                    min: 0,
                    max: function (value) {
                        return Number(value.max + 20).toFixed(0);
                    },
                    boundaryGap: [0, 0.01],
                },
                series: [
                    {
                        name: 'Water Level',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{c} cm.'
                        },
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        data: [0]
                    }
                ]
            },
            compareOption: {
                title: {
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: '{c} cm.'
                },
                toolbox: {
                    show: false,
                    feature: {
                        saveAsImage: {}
                    }
                },
                grid: {
                    left: '10%',
                    right: '10%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['Week-1', 'This week']
                },
                yAxis: {
                    type: 'value',
                    min: 0,
                    max: function (value) {
                        return Number(value.max + 20).toFixed(0);
                    },
                    boundaryGap: [0, 0.01],
                },
                series: [
                    {
                        name: 'Water Level',
                        type: 'bar',
                        barGap: 5,
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{c} cm.'
                        },
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        data: [10, 20]
                    }
                ]
            },
            option: {
                title: {
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    show: false
                },
                grid: {
                    left: '10%',
                    right: '10%',
                    top: '20%',
                    bottom: '10%'
                },
                toolbox: {
                    show: false,
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none'
                        },
                        dataView: { readOnly: false },
                        magicType: { type: ['line', 'bar'] },
                        restore: {},
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'time',
                    boundaryGap: false
                },
                yAxis: {
                    min: 0,
                    type: 'value',
                    max: (value) => {
                        if (this.asset.max > value.max) return this.asset.max + 20;
                        else return value.max
                    },
                    axisLabel: {
                        formatter: '{value} cm.'
                    }
                },
                dataset: {
                    dimensions: ['timestamp', 'level'],
                    source: []
                },
                series: [
                    {
                        name: 'Water Level',
                        type: 'line',
                        markPoint: {
                            data: [
                                { type: 'max', name: 'Max' },
                                { type: 'min', name: 'Min' }
                            ]
                        },
                        markLine: {
                            data: [{ type: 'average', name: 'Avg' }]
                        },
                        markArea: {
                            itemStyle: {
                                color: 'rgba(180, 180, 180, 0.2)'
                            },
                            data: (this.asset.min && this.asset.max) ? [[{ name: `Normal ${this.asset.min}-${this.asset.max} cm.`, yAxis: this.asset.min }, { yAxis: this.asset.max }]] : []
                        }
                    },

                ]
            }
        }
    },
    computed: {
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            }
        },
        levelData: {
            get: function () {
                return this.$store.state.Water.waterLevelData
            }
        },
        mainWaterLevel: {
            get: function () {
                return this.$store.state.Water.mainWaterLevel
            }
        },
        meterAsset() {
            if (this.$store.state.Asset.assetAll.filter((el) => el.deviceId === this.asset.meterId).length > 0) {
                return this.$store.state.Asset.assetAll.filter((el) => el.deviceId === this.asset.meterId)[0]
            } else {
                return {}
            }
        },
        pumpAsset() {
            if (this.$store.state.Asset.assetAll.filter((el) => el.deviceId === this.asset.pumpId).length > 0) {
                return this.$store.state.Asset.assetAll.filter((el) => el.deviceId === this.asset.pumpId)[0]
            } else {
                return {}
            }
        },
        pressureAsset() {
            if (this.$store.state.Asset.assetAll.filter((el) => el.assetId === this.asset.pressureId).length > 0) {
                return this.$store.state.Asset.assetAll.filter((el) => el.assetId === this.asset.pressureId)[0]
            } else {
                return {}
            }
        },
    },
    methods: {
        refresh() {
            this.loading = true
            if (this.asset.assetId) {
                this.setLevelGuage()
                this.getLevelTelemetry()
            }

            if (this.asset.pressureId) {
                this.setPressureGuage()
                this.getPressureTelemetry()
            }

            if (this.asset.meterId) {
                this.setMeterCompare()
                this.getMeterData()
            }

            if (this.asset.pumpId) {
                this.setPowerCompare()
                this.getPumpEnergy()
            }
            this.loading = false
        },
        getLevelTelemetry() {
            this.$store.dispatch('Asset/GET_TELEMETRY', {
                assetId: this.asset.assetId,
                start: Math.floor(this.range.startDate.getTime() / 1000),
                end: Math.floor(this.range.endDate.getTime() / 1000)
            }).then((data) => {
                this.level = data
                let series = []
                data.forEach(element => {
                    series.push([element.timestamp, Number(element.WaterLevel)])
                })
                this.levelChart.setOption(this.option);
                this.levelChart.setOption({
                    dataset: {
                        dimensions: ['timestamp', 'level'],
                        source: series,
                    },
                    dataZoom: [
                        {
                            type: 'inside',
                            start: 94,
                            end: 100
                        }
                    ],
                    series: [
                        {
                            markArea: {
                                itemStyle: {
                                    color: 'rgba(180, 180, 180, 0.2)'
                                },
                                data: (this.asset.min && this.asset.max) ? [[{ name: `Normal ${this.asset.min}-${this.asset.max} cm.`, yAxis: this.asset.min }, { yAxis: this.asset.max }]] : []
                            }
                        }]
                });
            }).catch((err) => {

            })
        },
        getPressureTelemetry() {
            this.$store.dispatch('Asset/GET_TELEMETRY', {
                assetId: this.asset.pressureId,
                start: Math.floor(this.range.startDate.getTime() / 1000),
                end: Math.floor(this.range.endDate.getTime() / 1000)
            }).then((data) => {
                let series = []
                data.forEach(element => {
                    series.push([element.timestamp, Number(element.WaterPressure)])
                })
                this.pressureChart.setOption({
                    title: {
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        show: false
                    },
                    grid: {
                        left: '10%',
                        right: '10%',
                        top: '20%',
                        bottom: '10%'
                    },
                    toolbox: {
                        show: false,
                        feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            dataView: { readOnly: false },
                            magicType: { type: ['line', 'bar'] },
                            restore: {},
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'time',
                        boundaryGap: false
                    },
                    yAxis: {
                        min: 0,
                        type: 'value',
                        max: function (value) {
                            return Number(value.max + 20).toFixed(0);
                        },
                        //min: 'dataMin',
                        axisLabel: {
                            formatter: '{value} psi.'
                        }
                    },
                    dataZoom: [
                        {
                            type: 'inside',
                            start: 94,
                            end: 100
                        }
                    ],
                    dataset: {
                        dimensions: ['timestamp', 'pressure'],
                        source: []
                    },
                    series: [
                        {
                            name: 'Water Pressure',
                            type: 'line',
                            markPoint: {
                                data: [
                                    { type: 'max', name: 'Max' },
                                    { type: 'min', name: 'Min' }
                                ]
                            },
                            markLine: {
                                data: [{ type: 'average', name: 'Avg' }]
                            }
                        },

                    ]
                }
                );
                this.pressureChart.setOption({
                    dataset: {
                        dimensions: ['timestamp', 'pressure'],
                        source: series,
                    }
                });
            }).catch((err) => {

            })
        },
        getPumpEnergy() {
            if (this.asset.pumpId === undefined) {
                return
            }
            this.$store.dispatch('Energy/GET_ENERGY', {
                BuildingId: this.building.BuildingId,
                deviceId: this.asset.pumpId,
                start: Math.floor(this.range.startDate.getTime()),
                end: Math.floor(this.range.endDate.getTime()),
                type: 'hourly',
                group: false
            }).then((data) => {
                let series = []
                let axis = []
                let sorted = data.sort((a, b) => {
                    let dateA = this.$dayjs(a.date + ' ' + a.H + ':00', "YY-MM-DD H:mm")
                    let dateB = this.$dayjs(b.date + ' ' + b.H + ':00', "YY-MM-DD H:mm")
                    if (dateA.isAfter(dateB)) {
                        return 1
                    } else {
                        return -1
                    }
                })
                sorted.forEach(element => {
                    series.push(Number(element.Unit).toFixed(2))
                    axis.push(this.$dayjs(element.date + ' ' + element.H + ':00', "YY-MM-DD HH:mm").format('DD MMM YY HH:mm'))
                })
                this.pumpChart.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                        }
                    },
                    toolbox: {
                        itemGap: 20,
                        show: true,
                        showTitle: false, // hide the default text so they don't overlap each other
                        feature: {
                            restore: { show: true },
                            magicType: {
                                type: ['line', 'bar']
                            },
                            saveAsImage: {
                                show: true,
                                title: 'Save As Image'
                            },
                            dataView: {
                                show: true,
                                title: 'Data View'
                            },
                        }
                    },
                    legend: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: axis
                    },
                    yAxis: {
                        type: 'value',
                        name: 'Usage (kWh)',
                        axisLabel: {
                            formatter: '{value} kWh'
                        }
                    },
                    series: {
                        name: 'Usage',
                        type: 'bar',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: series
                    },
                })
                // this.pumpChart.setOption(this.option);

            }).catch((err) => {

            })

        },
        getMeterData() {
            if (this.asset.meterId === undefined) {
                return
            }
            this.$store.dispatch('Water/GET_WATER_CONSUMPTION', {
                BuildingId: this.building.BuildingId,
                deviceId: this.asset.meterId,
                start: Math.floor(this.range.startDate.getTime()),
                end: Math.floor(this.range.endDate.getTime()),
                type: 'hourly',
                group: false
            }).then((data) => {
                let series = []
                let axis = []
                let sorted = data.sort((a, b) => {
                    let dateA = this.$dayjs(a.date + ' ' + a.H + ':00', "YY-MM-DD H:mm")
                    let dateB = this.$dayjs(b.date + ' ' + b.H + ':00', "YY-MM-DD H:mm")
                    if (dateA.isAfter(dateB)) {
                        return 1
                    } else {
                        return -1
                    }
                })
                sorted.forEach(element => {
                    series.push(Number(element.Unit).toFixed(2))
                    axis.push(this.$dayjs(element.date + ' ' + element.H + ':00', "YY-MM-DD HH:mm").format('DD MMM YY HH:mm'))
                })
                this.meterChart.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                        }
                    },
                    toolbox: {
                        itemGap: 20,
                        show: true,
                        showTitle: false, // hide the default text so they don't overlap each other
                        feature: {
                            restore: { show: true },
                            magicType: {
                                type: ['line', 'bar']
                            },
                            saveAsImage: {
                                show: true,
                                title: 'Save As Image'
                            },
                            dataView: {
                                show: true,
                                title: 'Data View'
                            },
                        }
                    },
                    legend: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: axis
                    },
                    yAxis: {
                        type: 'value',
                        name: 'Usage (Unit)',
                        axisLabel: {
                            formatter: '{value} Unit'
                        }
                    },
                    series: {
                        name: 'Usage',
                        type: 'bar',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: series
                    },
                });
            }).catch((err) => {

            })

        },
        setLevelGuage() {
            let value = JSON.parse(this.asset.data)
            this.levelGuage.setOption(this.barOption);
            this.levelGuage.setOption({
                title: {},
                series: [
                    {
                        name: 'Water Level',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{c} cm.'
                        },
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        data: [value.WaterLevel]
                    }
                ]
            })
        },
        setPressureGuage() {
            let value = JSON.parse(this.pressureAsset.data)
            this.pressureGuage.setOption({ ...this.barOption });
            this.pressureGuage.setOption({
                title: {},
                series: [
                    {
                        name: 'Water Pressure',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{c} psi.'
                        },
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        data: [value.WaterPressure]
                    }
                ]
            })
        },
        setMeterCompare() {
            this.$store.dispatch('Water/GET_WATER_CONSUMPTION', {
                BuildingId: this.building.BuildingId,
                deviceId: this.asset.meterId,
                start: Math.floor(this.$dayjs().local().subtract(1, 'week').startOf('week').valueOf()),
                end: Math.floor(this.$dayjs().local().valueOf()),
                type: 'weekly',
                group: false
            }).then((data) => {
                let series = data.map((el) => {
                    return el.Unit
                })
                this.meterCompare.setOption(this.compareOption);
                this.meterCompare.setOption({
                    title: {},
                    series: [
                        {
                            name: 'Water Consumption',
                            type: 'bar',
                            label: {
                                show: true,
                                position: 'top',
                                formatter: '{c} Unit'
                            },
                            showBackground: true,
                            backgroundStyle: {
                                color: 'rgba(180, 180, 180, 0.2)'
                            },
                            data: series
                        }
                    ]
                })
            }).catch((err) => {

            })



        },
        setPowerCompare() {
            this.$store.dispatch('Energy/GET_ENERGY', {
                BuildingId: this.building.BuildingId,
                deviceId: this.asset.pumpId,
                start: Math.floor(this.$dayjs().local().subtract(1, 'week').startOf('week').valueOf()),
                end: Math.floor(this.$dayjs().local().valueOf()),
                type: 'weekly',
                group: false
            }).then((data) => {
                let series = data.map((el) => {
                    return Number(el.Unit).toFixed(0)
                })
                this.energyCompare.setOption(this.compareOption);
                this.energyCompare.setOption({
                    title: {},
                    series: [
                        {
                            name: 'Energy Consumption',
                            type: 'bar',
                            label: {
                                show: true,
                                position: 'top',
                                formatter: '{c} kWh'
                            },
                            showBackground: true,
                            backgroundStyle: {
                                color: 'rgba(180, 180, 180, 0.2)'
                            },
                            data: series
                        }
                    ]
                })

            }).catch((err) => {

            })


        }
    },
    mounted() {
        if (this.asset.assetId) {
            this.levelChart = echarts.init(this.$refs.levelChart);
            this.levelGuage = echarts.init(this.$refs.levelGuage);
            const resizeObserver = new ResizeObserver((entries) => {
                this.levelChart.resize();
                this.levelGuage.resize()
            });
            resizeObserver.observe(this.$refs.levelChart);
            this.setLevelGuage()
            this.getLevelTelemetry()
        }

        if (this.asset.pressureId) {
            this.pressureChart = echarts.init(this.$refs.pressureChart);
            this.pressureGuage = echarts.init(this.$refs.pressureGuage);
            const resizeObserver = new ResizeObserver((entries) => {
                this.pressureChart.resize();
                this.pressureGuage.resize()
            });
            resizeObserver.observe(this.$refs.pressureChart);
            this.setPressureGuage()
            this.getPressureTelemetry()
        }

        if (this.asset.meterId) {
            this.meterChart = echarts.init(this.$refs.meterChart);
            this.meterCompare = echarts.init(this.$refs.meterCompare);
            const resizeObserver = new ResizeObserver((entries) => {
                this.meterChart.resize();
                this.meterCompare.resize()
            });
            resizeObserver.observe(this.$refs.meterChart);
            this.setMeterCompare()
            this.getMeterData()
        }

        if (this.asset.pumpId) {
            this.pumpChart = echarts.init(this.$refs.pumpChart);
            this.energyCompare = echarts.init(this.$refs.energyCompare);
            const resizeObserver = new ResizeObserver((entries) => {
                this.pumpChart.resize();
                this.energyCompare.resize()
            });
            resizeObserver.observe(this.$refs.pumpChart);
            this.setPowerCompare()
            this.getPumpEnergy()
        }
    }
}


</script>

<style scss scoped>
@-webkit-keyframes argh-my-eyes {
    0% {
        color: #FFEBEE !important;
    }

    49% {
        color: #FFEBEE !important;
    }

    50% {
        color: #E53935 !important;
    }

    99% {
        color: #E53935 !important;
    }

    100% {
        color: #FFEBEE !important;
    }
}

@-moz-keyframes argh-my-eyes {
    0% {
        color: #FFEBEE !important;
    }

    49% {
        color: #FFEBEE !important;
    }

    50% {
        color: #E53935 !important;
    }

    99% {
        color: #E53935 !important;
    }

    100% {
        color: #FFEBEE !important;
    }
}

@keyframes argh-my-eyes {
    0% {
        color: #FFEBEE;
    }

    49% {
        color: #FFEBEE;
    }

    50% {
        color: #E53935;
    }

    99% {
        color: #E53935;
    }

    100% {
        color: #FFEBEE;
    }
}

.blink {
    -webkit-animation: argh-my-eyes 2s infinite !important;
    -moz-animation: argh-my-eyes 2s infinite !important;
    animation: argh-my-eyes 2s infinite !important;
}
</style>